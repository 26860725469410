<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  components: {},
  methods: {
    handleVisibilityChange() {
      if (document.hidden) {
        document.title = 'Come back!';
      } else {
        document.title = this.$route.meta.title + ' - ' + 'InterRail'
      }
    }
  },
};

</script>

<style>
::selection {
  background-color: rgb(23, 162, 184);
  color: white;
}
</style>
